.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.7rem 0 0.7rem 0;
    border: 0.44375em solid transparent;
    border-top: 0.64375em solid transparent;
}

// Colocación del label al estar focus
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.5em) scale(0.75);
}

// bordes del input planos
.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 0px 0 0 0px !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 0px 0px 0 !important;
}

// color label que se mueve para arriba cuando focus
.mat-form-field.mat-focused .mat-form-field-label {
    color: var(--secondary);
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #f44336;
}
// color outline que rodea el input cuando focus
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: var(--secondary);
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 !important;
    padding-bottom: 0.7em !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    position: relative;
}
.search-input {
    border-radius: 20px;
    border-color: var(--secondary);
    padding: 5px 15px;
    border: 1px solid var(--grey);
    max-width: 100%;
    background-color: var(--white)!important;
    &.light {
        border: none;
        background-color: var(--primary-lightest);
    }
}
