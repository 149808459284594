.mat-button,
.mat-button-wrapper {
  font-family: "Nunito Sans", sans-serif;
}
.mat-button.mat-primary {
  color: #5469f8;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primary) !important;
}

.mat-expansion-indicator {
  margin-top: -8px;
}
.mat-expansion-panel-content {
  font-family: "Nunito Sans", sans-serif !important;
}
/* FIX SCROLL IOS */
.mat-drawer-content,
.mat-sidebar-content {
  -webkit-overflow-scrolling: touch;
  /* overflow: scroll;*/
}

.custom-snack {
  background: #3f51b5;
  color: white;
  .mat-button-wrapper {
    color: #2ba790;
  }
}

.mat-progress-bar-fill::after {
  background-color: var(--primary);
}

.mat-progress-bar-buffer {
  background-color: var(--primary-lightest);
}

.mat-progress-bar {
  border-radius: 2px;
}

mat-form-field.back-white {
  .mat-form-field-outline {
    background: white;
    opacity: 1;
  }
}

@media (max-width: 576px) {
  .cdk-overlay-pane.more-info {
    max-width: 100vw !important;
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primary);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--primary-light);
}

mat-optgroup.short mat-option,
mat-optgroup.short .mat-optgroup-label {
  line-height: 2em !important;
  height: 2em !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--primary);
}

mat-form-field.small {
  .mat-form-field-wrapper {
    padding: 0px !important;
  }
  .mat-form-field-subscript-wrapper {
    display: none;
  }
}
mat-form-field.no-border {
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end,
  .mat-form-field-outline-start {
    border-width: 1px !important;
    border-color: var(--grey) !important;
  }
}

.mat-dialog-container {
  background-color: white;
}

.cdk-overlay-pane {
  &.no-padding {
    mat-dialog-container {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 15px;
      .close {
        z-index: 99;
        top: 8px;
        right: 10px;
      }
      > div {
        padding: 0 !important;
      }
    }
  }
  &.overflow-hidden {
    mat-dialog-container {
      overflow: hidden !important;
    }
  }
}

.mat-mdc-select-panel {
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  background: white;
}

mat-option {
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}
.mat-mdc-select {
  padding: 7px 0px;
}
.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12)!important;
  border-width: 1px!important;
}
