:root {
  --tui-base-01: var(--white);
  --tui-info-fill: var(--primary);
  --tui-info-bg: transparent;
  --tui-primary-hover: var(--primary-light);
  --tui-primary: var(--primary);
  --tui-primary-active: var(--primary);
  --tui-base-04: var(--grey);
  // --tui-error-bg: var(--danger);
}
tui-notification-alert {
  &.alert {
    padding: 0px !important;
  }
}

tui-tooltip.small {
  width: 1rem;
  height: 1rem;
  tui-svg {
    width: 1rem;
    height: 1rem;
    font-size: 0.8rem;
  }
}
button {
  tui-loader {
    width: 20px;
  }
}
tui-radio {
  tui-wrapper {
    &:after {
      color: var(--primary) !important;
    }
  }
  tui-wrapper[data-appearance='outline'] {
    &:after {
      color: var(--border-color) !important;
    }
  }
}

tui-dialog {
  &.video-size {
    height: 800px !important;
    width: 1200px !important;
    max-height: 90vw !important;
    max-width: 95vw !important;
    > .content,
    > .t-content {
      height: 100%;
      padding: 0px;
      section {
        height: 100%;
      }
    }
  }
  &.nettie-dialog-size {
    height: 90vh !important;
    width: 95vw !important;
    max-height: 90vh !important;
    max-width: 1300px !important;
    > .content,
    > .t-content {
      height: 100%;
      padding: 0px;
      section {
        height: 100%;
      }
    }
    overflow: hidden;
    tui-svg {
      margin-right: 30px;
    }
  }
}
tui-dialog {
  > .content,
  > .t-content {
    padding: 0px !important;
    > h2 {
      padding: 1rem;
      margin: 0px !important;
    }
  }
}
tui-input,
tui-input-password {
  label {
    margin-bottom: 0px !important;
  }
}
tui-wrapper[data-appearance='textfield'][data-state='readonly']:after {
  background-color: lightgray !important;
  opacity: 0.5;
}

tui-hint-box {
  background: var(--secondary) !important;
}

tui-checkbox {
  tui-svg{
    background: var(--primary)!important;
  }
}