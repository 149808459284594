@media print {    
    .no-print, .no-print * {
      display: none !important;
    }
    .t-close{
      display: none !important;
    }
    .overlay_visible{
      display: none!important;
    }
    // .container {
    //   min-width: auto !important;
    // }
    tui-dialog{
      display: none!important;
    }
    .btn-adntro, app-feedback, .fa-chevron-down, .fa-chevron-up, .pill-date, .card-image {
      display: none !important;
    }

    .adntro-card.arrows .adntro-card-header:before, .adntro-card.arrows .adntro-card-header:after {
      content: "" !important;
    }
  
    .cdk-overlay-container { display: none !important; }
  
    // * { overflow: visible !important; }
    thead { display: table-header-group; }
    // body { margin: 0; }
  
    .trait-print-page {
      display: block !important;
      -webkit-print-color-adjust: exact;
    }
  
    .page-header {
      -webkit-print-color-adjust: exact;
    }
  
    .trait-header-info-disclaimer {
      -webkit-print-color-adjust: exact;
    }
  
    app-chart-left-right-slim {
      -webkit-print-color-adjust: exact;
    }
  
    // table {page-break-after:always !important; overflow: hidden;}
  
    mat-dialog-container {
      overflow: visible !important;
    }
  
    body {
      margin: 0 !important;
      overflow:visible !important;
      position: static !important;
      background-color: white !important;
      -webkit-print-color-adjust: exact;
    }
    html {
      overflow: visible !important;
      position: static !important;
    }
  
    .main-container {
      overflow: visible !important;
      -webkit-print-color-adjust: exact;
      background-color: white !important;
    }
  
    .main-content-wrapper {
      -webkit-print-color-adjust: exact;
      background-color: white;
      padding:  0 !important;
    }

    app-root {
      background-color: white !important;
      -webkit-print-color-adjust: exact;
      height: auto;
      overflow: visible !important;
      position: static !important;
    }
  }
    
  @page {
    margin: 5mm 10mm;
  }