.adn-button {
  --adn-button-color: var(--secondary);
  --adn-button-bg: white;
}

.adn-button, .adn-button-primary,.adn-button-transparent, .adn-button-secondary, .adn-button-icon {
  color: var(--adn-button-color);
  background-color: var(--adn-button-bg);
  font-size: var(--adn-text-font-size-sm);
  border: none;
  cursor: pointer;
  border-radius: 50rem !important;
  font-weight: bold;
  text-transform: uppercase;
  box-sizing: border-box;
  white-space: nowrap;
  display: inline-block;
  padding: 3px 15px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px;
  transition: transform 0.1s ease;
  &.disabled, &:disabled {
    opacity: 0.6;
    cursor: auto;
  }  
  &:hover:not([disabled]), &.focus:not([disabled]), &:focus:not([disabled]),
  &:focus, &.focus {
    transform: translateY(-1px);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 3px 0px, rgba(0, 0, 0, 0.08) 0px 2px 2px 0px;
  }
  @media (min-width: 767px) {
    font-size: var(--adn-text-font-size-xs-desktop);
  }
}
.adn-button {
  background-color: var(--adn-button-bg);
}
.adn-button-primary {
  --adn-button-bg: var(--primary);
  &.disabled, &:disabled {
    --adn-button-bg: var(--primary);
  }  
}
.adn-button-primary-light {
  --adn-button-bg: var(--adn-color-primary-light);
}
.adn-button-primary-light-2 {
  --adn-button-bg: var(--adn-color-primary-light-2);
}
.adn-button-secondary {
  --adn-button-bg: var(--adn-color-genetic-dark);
  --adn-button-color: var(--white);
}
.adn-button-white {
  --adn-button-color: var(--adn-color-genetic-dark);
  --adn-button-bg: var(--white);
}
.adn-button-accent {
  --adn-button-bg: var(--adn-color-yellow);
}
.adn-button-danger {
  --adn-button-bg: var(--adn-color-orange);
}
.adn-button-transparent {
  background-color: rgba(255,255,255,0);
  box-shadow: none;&:hover:not([disabled]), &.focus:not([disabled]), &:focus:not([disabled]),
  &:focus, &.focus {
    box-shadow: none;
  }
}
.adn-button-small {
  font-size: var(--adn-text-font-size-xs);
  padding: 1px 8px;
}
.adn-button-big {
  font-size: var(--adn-text-font-size-sm);
  padding: 10px;
}
.adn-button-large {
  font-size: var(--adn-text-font-body);
}

.adn-button-icon-float-right {
  position: relative;

  i {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }
}

.adn-button-icon {
  background-color: rgba(255,255,255,0);
  box-shadow: none;
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0;
  margin: 0;
  i {
    transform: scale(1.3);
  }
  &.adn-button-small {
    width: 18.5px;
    height: 18.5px;
    line-height: 18.5px;
    i {
      transform: scale(1);
    }
  }
  &.adn-button-large {
    width: 30px;
    height: 30px;
    line-height: 30x;
    i {
      transform: scale(1.1);
    }
  }
  &:hover{
    box-shadow: none;
  }
}



// LEGACY

/**
 * BUTTON
 * =====================
 *
 * En general lo utilizaremos como <button class="btn-adntro">
 *   - Es el botón en tamaño normal y fondo blanco con opacidad
 * Modificaciones
 *   - .adntro-primary : Botón primario (verde)  ( <button class="btn-adntro adntro-primary"> )
 *   - .adntro-inverted : Botón oscuro (verde)  ( <button class="btn-adntro adntro-inverted"> )
 *   - .adntro-small :   Botón más pequeño (no afecta al color)  ( <button class="btn-adntro adntro-primary adntro-small"> )
 *   - .adntro-big :   Botón más grande (no afecta al color)  ( <button class="btn-adntro adntro-primary adntro-big"> )
 *   - .adntro-big :   Botón más pequeño (no afecta al color)  ( <button class="btn-adntro adntro-primary adntro-big"> )
 */

// .btn-adntro Base botón
.btn-adntro {
  border: none;
  font-size: 0.87rem;
  border-radius: 50rem !important;
  font-weight: bold;
  padding: 3px 15px;
  line-height: 1.2em;
  color: #263a49;
  background-color: rgba(255, 255, 255, 0.7);
  transition: background-color, border-color, color 0.4s ease;
  text-transform: uppercase;
  box-sizing: border-box;
  display: inline-block;
  font-size: 1rem;
  padding: 8px 20px;
  &:hover:not([disabled]),
  &.focus:not([disabled]),
  &:focus:not([disabled]) {
    background-color: rgba(255, 255, 255, 1);
    color: black;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 5px 2px rgba(38, 209, 180, 0.5);
  }

  &.disabled,
  &:disabled {
    opacity: 0.7;
  }
}

// .adntro-primary

.btn-adntro.adntro-primary {
  color: #263a49;
  background-color: var(--primary);
  border: none;

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.focus:not([disabled]) {
    color: var(--primary);
    background-color: #263a49;
  }
}

.btn-adntro.adntro-light {
  background: #b6e7e340;
  font-weight: bold;
  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.focus:not([disabled]) {
    background: var(--primary-light);
  }
}
.btn-adntro.adntro-white {
  background: #fff;
  font-weight: bold;
  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.focus:not([disabled]) {
    background: #fff;
  }
}

// .adntro-inverted

.btn-adntro.adntro-inverted {
  color: var(--primary);
  background-color: #263a49;
  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.focus:not([disabled]) {
    color: #263a49;
    background-color: var(--primary);
  }
}
.btn-adntro.adntro-red {
  color: #fff;
  background-color: var(--africa);
  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.focus:not([disabled]) {
    color: #fff;
    background-color: var(--africa);
  }
}

// .adntro-small
.btn-adntro.adntro-small {
  font-size: 0.8rem;
  padding: 3px 10px;
}

// .adntro-big
.btn-adntro.adntro-big {
  font-size: 1.2rem;
  padding: 8px 20px;
}
// .adntro-big
.btn-adntro.adntro-biggest {
  font-size: 1.2rem;
  padding: 10px 20px;
}

.btn-adntro.adntro-raised {
  box-shadow: 0px 4px 5px 0px #0000003d;
}

.btn-adntro.adntro-amazon {
  background: #ffd814;
  border-color: #fcd200;
  &:hover {
    background: #f7ca00;
    border-color: #f2c200;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
  }
}

.btn-adntro.adntro-inverse {
  color: #263a49;
  margin: 0;
  background: transparent;
  border: none;

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.focus:not([disabled]) {
    color: var(--primary);
  }
}

.btn-adntro.btn-lowercase {
  text-transform: none;
}

.btn-adntro-custom {
  background: var(--white);
  border-radius: 15px;
  -webkit-box-shadow:  0px 1px 8px 0px rgba(38,58,73,0.4); 
  box-shadow:  0px 1px 8px 0px rgba(38,58,73,0.4);
  display: flex;
  flex-direction: row;
  max-width: 500px;
  padding: 20px;
  align-items: center;
  position: relative;

  &:hover {
    cursor: pointer;
    background: #263A49;

    .btn-adntro-custom-labels {

      .btn-adntro-custom-label-title {
        color: var(--white);
      }

      .btn-adntro-custom-label-activate {
        color: var(--white);
      }
    }
  }

  .btn-adntro-custom-img {
    flex: 0 0 60px;

    img {
      width: 100%;
    }
  }

  .btn-adntro-custom-labels {
    flex: 1 0 10px;
    display: flex;
    flex-direction: column;
    padding-left: 16px;

    .btn-adntro-custom-label-title {
      margin-bottom: 4px;
      color: #263A49;
      font-size: 14px;
      font-weight: bold;
      text-align: left;
    }

    .btn-adntro-custom-label-subtitle {
      color: #AFAFAF;
      font-size: 12px;
      font-weight: bold;
      text-align: left;
    }

    .btn-adntro-custom-label-activate {
      position: absolute;
      right: 30px;
      bottom: 10px;
      font-size: 12px;
      font-weight: bold;
      color: #263A49;
    }

    .btn-adntro-custom-label-activate {
      display: none;
    }
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .btn-adntro-custom {

    .btn-adntro-custom-labels {

      .btn-adntro-custom-label-activate {
        display: block;
      }
    }
  }
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .btn-adntro-custom {
    padding: 36px 26px;

    .btn-adntro-custom-img {
      flex: 0 0 75px;
  
      img {
        width: 100%;
      }
    }

    .btn-adntro-custom-labels {
      padding-left: 16px;

      .btn-adntro-custom-label-title {
        font-size: 16px;
      }

      .btn-adntro-custom-label-subtitle {
        font-size: 14px;
        width: 80%;
      }

      .btn-adntro-custom-label-activate {
        display: block;
      }
    }
  }
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
}