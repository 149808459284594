
@import "./bootstrap4-theme.scss";
@import "./themes/adntro.scss";
@import "./helper.scss";
@import "./shared.scss";
body,
html {
  position: fixed;
  background-color: var(--secondary);
}

@import "./print.scss";
@import "./spaces.scss";
@import "./typography.scss";
@import "./colors.scss";

@import "./override-stripe.scss";
@import "./override-amcharts.scss";
@import "./override-bootstrap.scss";
@import "./indigo-pink-legacy.scss";
@import "./override-material.scss";
@import "./override-taiga.scss";
@import "./override.scss";

@import "./components/loader.scss";
@import "./components/buttons.scss";
@import "./components/links.scss";
@import "./components/card.scss";
@import "./components/navigation.scss";
@import "./components/steps.scss";
@import "./components/header-section.scss";
@import "./components/input.scss";
@import "./components/radio.scss";
@import "./components/checkbox.scss";
@import "./components/separator.scss";