a {
    color: var(--secondary);
    &:hover {
        color: var(--primary);
    }
    &.adn-link-accent {
        color: var(--primary);
    }
    &.adn-link-underline {
        color: var(--secondary);
        text-decoration: underline;
    }
}
