.adn-space-xs {
  padding: 8px 0px;
  @media (min-width: 768px) {
    padding: 12px 0px;
  }
}

.adn-space-sm {
  padding: 12px 0px;
  @media (min-width: 768px) {
    padding: 18px 0px;
  }
}

.adn-space-md {
  padding: 18px 0px;
  @media (min-width: 768px) {
    padding: 27px 0px;
  }
}

.adn-space-lg {
  padding: 22px 0px;
  @media (min-width: 768px) {
    padding: 33px 0px;
  }
}

.adn-space-xl {
  padding: 27px 0px;
  @media (min-width: 768px) {
    padding: 40px 0px;
  }
}
