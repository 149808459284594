.steps {
  box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.3);
  background-color: var(--adn-color-white);
  padding: 0;
  position: relative;
  margin: auto;
  min-height: calc(100vh - 52px);
  @media (min-width: 576px) {
    border-radius: 10px;
    min-height: auto;
  }
  .step {
    position: relative;
    transition: all 0.4s ease-in-out;
    margin-bottom: 1px;
    .step-header {
      padding: 15px;
      background: var(--primary);
      .fa-check-square {
        color: green;
      }
    }
    @media (min-width: 576px) {
      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .step-header {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }

    .step-content {
      padding: 0px 15px;
      overflow: hidden;
      position: relative;
      min-height: 50px;
      width: 100%;
      // background-color: rgba(0, 0, 0, 0.05);
      border-radius: 4px;
    }
    &.minimized {
      background-color: #fff;
      cursor: pointer;
      > .step-content {
        height: 0px;
        min-height: 0px;
      }
      &:hover {
        background-color: #d4f3f050;
      }
      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .step-header {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
}
