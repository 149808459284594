.navigation-container {
    padding-top: 10px;
    text-align: center;
    position: relative;
    z-index: 1;
    --soft-color: var(--white);
    --accent-color: var(--nutrition);
    .navigation-title {
        font-size: 20px;
        @media (min-width: 576px) {
            font-size: 35px;
            margin: 20px auto;
        }
    }
    .navigation-top {
        margin-bottom: 0px;
        padding: 0px;
        padding-bottom: 10px;
        overflow: hidden;

        li {
            list-style-type: none;
            color: var(--secondary);
            font-size: 10px;
            float: left;
            position: relative;
            cursor: pointer;
            @media (min-width: 576px) {
                font-size: 1rem;
            }

            &:before {
                content: " ";
                font-family: "Font Awesome 5 Pro";
                font-weight: 100;
                font-size: 18px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                display: block;
                background: var(--soft-color);
                border-radius: 50%;
                margin: 0 auto 10px auto;
            }
            &:after {
                content: "";
                width: 100%;
                height: 10px;
                background: var(--soft-color);
                position: absolute;
                left: 0;
                top: 21px;
                z-index: -1;
            }
            &:first-child{
                &:after {
                    border-top-left-radius: 7px;
                    border-bottom-left-radius: 7px;
                }
            }
            &:last-child {
                &:after {
                    width: 100%;
                    border-top-right-radius: 7px;
                    border-bottom-right-radius: 7px;
                }
            }
            // &:hover {
            //     &:after,
            //     &:before {
            //         background: var(--primary-lightest);
            //     }
            // }
            &.active {
                font-weight: bold;
                &:before{
                    background: var(--accent-color);
                }
                &:after {
                    background: var(--accent-color);
                    // color: white;
                }
            }
        }
        @media (max-width: 576px) {
            border-bottom: 1px solid var(--border-color);
        }
    }
    .navigation-buttons {
        margin: 15px auto;
        @media (max-width: 576px) {
            position: fixed;
            bottom: 0px;
            z-index: 99;
            left: 0px;
            width: 100%;
            background: #243c47;
            padding: 15px;
            margin: 0px;
        }
    }
    .navigation-content {
        padding-bottom: 130px;
    }
}
