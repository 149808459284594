.header-section {
    width: 100%;
    height: 165px;
    position: relative;
    @media (min-width: 767px) {
        height: 350px;
    }
    .bg-header-section {
        width: 100%;
        height: 100%;
        background-size: cover;
        &:after {
            content: " ";
            width: 100%;
            height: 100px;
            bottom: 0;
            position: absolute;
            left: 0px;
            background-image: linear-gradient(0, rgb(0 0 0 / 86%) 0%, transparent 100%);
        }
    }

    &.no-shadow {
        .bg-header-section {
            &:after {
                content: none;
            }
        }
    }

    .overlay {
        width: 100%;
        bottom: 0px;
        position: absolute;
    }
    h1 {
        text-align: center;
        color: white;
        position: absolute;
        bottom: -1px;
        width: 100%;
        margin: auto;
        border-bottom: 4px solid var(--primary);
        font-size: 32px;
        font-weight: bold;
        a {
            color: white;
            margin-left: 5px;
        }
        @media (min-width: 767px) {
            font-size: 48px;
        }
    }
    &.medium {
        @media (min-width: 767px) {
            height: 250px;
        }
    }
    &.small {
        h1 {
            font-size: 25px;
            @media (min-width: 767px) {
                font-size: 32px;
            }
        }
        @media (min-width: 767px) {
            height: 200px;
        }
    }
    a.anchor {
        position: absolute;
        right: 10px;
        top: 20px;
    }
}
