// .loader-general {
//     position: fixed;
//     left: calc(50% - 50px);
//     margin-top: 10vh;
//     img {
//         animation: rotation 20s infinite linear;
//         max-width: 100px;
//         height: 100px;
//     }

//     @keyframes rotation {
//         0% {
//             transform: rotate(0deg);
//         }

//         100% {
//             transform: rotate(359deg);
//         }
//     }
// }

.loader-section {
    width: 150px;
    max-width: calc(100% - 30px);
    margin: 25px auto;
    mat-progress-bar {
        position: fixed;
        top: 160px;
        z-index: 997;
        width: 150px;
        margin: auto;
    }
    @media (min-width: 767px) {
        width: 350px;
        mat-progress-bar {
            width: 350px;
            top: 245px;
        }
    }
}
