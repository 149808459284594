.space-top-1 {
    padding-top: 2rem;
}
.space-bottom-1 {
    padding-bottom: 2rem;
}
.space-1 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.space-top-2 {
    padding-top: 4rem;
}
.space-bottom-2 {
    padding-bottom: 4rem;
}
.space-2 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.space-top-3 {
    padding-top: 6rem;
}
.space-bottom-3 {
    padding-bottom: 6rem;
}
.space-3 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

@media (min-width: 576px) {
    .w-sm-50 {
        width: 50% !important;
    }
    .w-sm-75 {
        width: 75% !important;
    }
}

@media (min-width: 768px) {
    .w-md-50 {
        width: 50% !important;
    }
    .w-md-75 {
        width: 75% !important;
    }
}

@media (min-width: 992px) {
    .w-lg-50 {
        width: 50% !important;
    }
    .w-lg-75 {
        width: 75% !important;
    }
}

.flex-col {
    display: flex;
    flex-direction: column;
}
.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.gap-1 {
    gap: 0.25rem;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-3 {
    gap: 1rem;
}
.gap-4 {
    gap: 1.5rem;
}


.flex1,
.flex-1 {
    flex: 1;
}

.cursor-pointer {
    cursor: pointer;
}
.cursor-none {
    cursor: none;
}
.cursor-help {
    cursor: help;
}

.color-aqua {
    color: var(--primary);
}
.color-green {
    color: #2ba790;
}
.color-health {
    color: #2ba790;
}
.color-yellow {
    color: #e4b26e;
}
.color-ancestry {
    color: #e4b26e;
}
.color-nutrition {
    color: #2ba7a7;
}
.color-nutrition-fitness {
    color: #91bda1;
}
.color-health-red {
    color: #d39aa9;
}
.color-personality {
    color: #f7e48b;
}
.color-pharma {
    color: #647bb5;
}
.color-labs-green {
    color: #4691a0;
}
.color-labs {
    color: #486c93;
}
.color-gray {
    color: #8d8d8d;
}
.color-gray {
    color: #8d8d8d;
}
.color-pink {
    color: #ff9caf;
}
.color-blue {
    color: #263a49;
}
.color-red {
    color: #f94259;
}
.color-white {
    color: #fff;
}

.no-border {
    border-radius: 0px !important;
}

.alert-blue {
    color: var(--secondary);
}

.min-vh-100 {
    min-height: 100vh;
}

.pill-date {
    position: relative;
    display: inline-block;
    background: var(--primary-light);
    height: 26px;
    line-height: 26px;
    border-bottom-right-radius: 7px;
    padding: 0px 13px;
    border-top-left-radius: 7px;
    &.nutrition {
        background: var(--nutrition) !important;
        color: var(--white) !important;
    }
}

.border-right {
    border-right: 1px solid #dedfe0;
}

.border-left {
    border-left: 1px solid #dedfe0;
}

.underline {
    text-decoration: underline;
}
.hide-scrollbar {
    overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
        display: none;
    }
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.text-underline-hover {
    &:hover {
        text-decoration: underline;
    }
}

.spinner-border {
    border-width: 1px;
    height: 10px;
    width: 10px;
}

.text-hover-underline{
    &:hover{
        text-decoration: underline;
    }
}