:root {
  --adn-card-border-radius: 15px;
  --adn-card-separator: rgba(51, 51, 51, 0.15);
}

.adn-card {
  background-color: white;
  border: none;
  border-radius: var(--adn-card-border-radius);
}
.adn-card-dark {
  background-color: var(--adn-color-genetic-dark);
  color: var(--adn-color-white);
  border: none;
  border-radius: var(--adn-card-border-radius);
}
.adn-card .adn-card-separator {
  height: 1px;
  width: 100%;
  background-color: var(--adn-card-separator);
}
.adn-card .adn-card-item {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  border-top: 1px solid transparent;
  border-top-color: var(--adn-card-separator);
}
/////////// LEGADCY

.card {
  background-color: white;
  border: none;
  border-radius: var(--adn-card-border-radius);
  width: 100%;
  // box-shadow: 0 4px 2px 0 var(--grey);
}

.card-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.adntro-card {
  .adntro-card-header {
    position: relative;
    background: var(--adn-color-primary);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 10px 40px;
    text-align: center;
    h3 {
      font-size: var(--adn-text-font-size-h3);
      line-height: 1;
      font-weight: 600;
      color: var(--adn-text-color-title);
      @media (min-width: 767px) {
        font-size: var(--adn-text-font-size-h3-desktop);
      }
      margin: 0px;
      @media (min-width: 576px) {
        //line-height: 57px;  // en titulos multilinea como deportes esto rompe
      }
    }
    &.nutrition {
      background: var(--nutrition);
    }
    .back {
      display: block;
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 25px;
      color: var(--secondary);
      @media (min-width: 576px) {
        display: none;
      }
    }
  }
  .card-image {
    min-height: 238px;
    @media (max-width: 768px) {
      max-height: 180px;
    }
    background-position: center center;
    background-size: cover;
  }
  .card-intro {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .adntro-card-body {
    display: block;
    position: relative;
    background-color: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &.border-top {
      border-radius: 15px;
    }

    &--no-header {
      border-radius: 15px;
      overflow: auto;
    }
  }

  .adntro-card-footer {
    display: flex;
    align-items: center;
    min-height: 42px;
    border-top: 1px solid var(--border-line-color);
  }

  &.arrows {
    position: relative; // Anclar etiqueta en la esquina superior izquierda
    .adntro-card-header {
      cursor: pointer;
      &:before,
      &:after {
        position: absolute;
        right: 15px;
        top: 30%;
        color: var(--secondary);
        font-family: 'Font Awesome 5 Pro';
      }
      &:before {
        content: '\f077';
        display: block;
      }
      &:after {
        content: '\f078';
        display: none;
      }
    }
  }
  &.closed {
    &.arrows {
      .adntro-card-header {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        &:before {
          display: none;
        }
        &:after {
          display: block;
        }
      }
    }
    > div:not(.adntro-card-header) {
      display: none;
    }
  }
  &.small {
    width: 390px;
    max-width: 100%;
  }
  &.centered {
    text-align: center;
  }
}

.adntro-card-section {
  text-align: center;
  background-color: var(--adn-color-primary-light-2);
  &.nutrition {
    background-color: var(--nutrition-light);
  }
}

.mw-300 {
  min-width: 300px;
}

// dashboard cards
.section-area {
  margin: auto;
  padding-bottom: 30px;
  // padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1364px;
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
    gap: 1rem;
    padding: 0;
    list-style-type: none;

    @media (min-width: 576px) {
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }
    .fa-lock-alt.big {
      font-size: 25px;
    }
  }
  .section-title {
    text-align: left;
    font-weight: normal;
    margin-bottom: 5px;
    display: inline-block;
    padding: 5px 20px;
    padding-left: 0px;
    font-size: 1.6rem;
    text-align: center;
    width: 100%;
    // display: none;
    img {
      transform: translateY(-3px);
      height: 30px;
      margin-right: 10px;
      display: none;
    }
    @media (min-width: 576px) {
      font-size: 1.8rem;
      text-align: left;
      width: auto;
    }
  }
}

.mw-300 {
  min-width: 300px;
}
.card-banner {
  background-color: var(--grey-light);
  padding: 17px 12px;
  border-radius: 7px;
  text-align: left;
  @media (min-width: 992px) {
    padding: 25px 40px;
  }
}
