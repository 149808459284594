.separator {
  margin: 0 auto;
  height: 4px;
  width: 300px;
  max-width: 60vw;
  margin-top: 0.6rem;
  margin-bottom: 1.5rem;
  background-color: var(--primary);
  &.left {
    width: 80%;
    text-align: left;
    margin: 0px;
  }
}
