html,
body {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
}

.border-card {
  border-radius: 14px;
}

*:focus {
  outline: none !important;
}

.btn:not([href]):not([type]):not(:disabled):not(.disabled) {
  cursor: pointer;
}

a {
  color: var(--secondary);
  &:hover {
    color: var(--primary);
  }
  &.link-accent {
    color: var(--primary);
  }
  &.link-black {
    color: var(--secondary);
    text-decoration: underline;
    &:hover {
      color: var(--secondary);
    }
  }
}

.tooltip-container {
  max-width: 320px;
  &.max-height {
    max-height: 400px;
    overflow: auto;
  }
}

.max-lines-10 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}

.inner-white {
  > * {
    color: white !important;
  }
}
.text-underline {
  text-decoration: underline !important;
}

.fa-reverse {
  transform: scaleX(-1);
}
.opacity-0 {
  opacity: 0;
}

.inner-html-p {
  line-height: 0 !important;
  > p {
    line-height: 1.4em !important;
  }
  ul {
    li {
      line-height: 1.4em !important;
      list-style: disc;
      margin-left: 40px;
    }
  }
  &.no-margin {
    > p {
      margin: 0px;
    }
  }
  &.inline {
    > p {
      display: inline-block;
    }
  }
}

.hide-scrollbars::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
}

.hide-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

input{
  font-size: 16px!important;
}

.border-card{
  border-radius: 14px;
}

.description-card-body-yellow {
  background-color: var(--adn-color-yellow-light-2);
  margin-bottom: 0px;
  padding: 11px;
  border-radius: 7px;
}