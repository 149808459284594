.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--primary);
}

.mat-checkbox {
    font-family: "Nunito Sans" !important;
}

mat-checkbox.adn-checkbox {
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: var(--primary);
    }
    .mat-checkbox-inner-container {
        margin-top: 1px !important;
    }
    a {
        text-decoration: underline;
    }
}
