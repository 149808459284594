#continents-chart-div
  > div
  > svg
  > g
  > g:nth-child(2)
  > g:nth-child(1)
  > g
  > g
  > g
  > g:nth-child(2)
  > g
  > g
  > g:nth-child(2)
  > g:nth-child(3)
  > g
  > text
  > tspan {
  display: none;
}
#continents-chart-div > div > svg > g > g:nth-child(2) > g:nth-child(2) > g > g:nth-child(3),
#chartdiv-country > div > svg > g > g:nth-child(2) > g:nth-child(2) > g > g:nth-child(3),
#chartdiv-regional > div > svg > g > g:nth-child(2) > g:nth-child(2) > g > g:nth-child(3),
#chart-diversity > div > svg > g > g:nth-child(2) > g:nth-child(2) > g > g:nth-child(3) {
  transform: translateY(-20px) translatex(20px);
}

#chartbanta > div > svg > g > g:nth-child(2) > g:nth-child(2) > g{
  // transform: translate(-50px, 531px);
  opacity: 0;
}
