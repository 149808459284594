:root {
    --adn-color-transparent: rgba(0, 0, 0, 0);
    --adn-color-white: white;
    --adn-color-black: #333;

    // Genetic Dark
    --adn-color-genetic-dark: #243c47;
    --adn-color-genetic-dark-light: #3c6877;
    --adn-color-genetic-dark-light-2: #64a7bf;

    // Primary
    --adn-color-primary: #66e6d8;
    --adn-color-primary-light: #b6e7e3;
    --adn-color-primary-light-2: #d5f1ef;

    // Pink
    --adn-pink: #ff9caf;
    --adn-pink-light: #ffc3cf;
    --adn-pink-light-2: #ffe1e7;

    // YELLOW
    --adn-color-yellow: #f1d30b;
    --adn-color-yellow-light: #f6e784;
    --adn-color-yellow-light-2: #fff9d0;

    // ORANGE
    --adn-color-orange: #ff782c;
    --adn-color-orange-light: #ffc19e;
    --adn-color-orange-light-2: #ffd7c0;

    // ORANGE 2
    --adn-color-orange-2: #ffb12c;
    --adn-color-orange-2-light: #ffd080;
    --adn-color-orange-2-light-2: #ffe8c0;

    // BLUE
    --adn-color-blue: #8dccff;
    --adn-color-blue-light: #b0dcff;
    --adn-color-blue-light-2: #ddf0ff;

    // PURPLE
    --adn-color-purple: #a5a6f6;
    --adn-color-purple-light: #c9cafa;
    --adn-color-purple-light-2: #e4e4fc;

    // CTA
    --adn-color-cta: var(--adn-color-yellow);
    --adn-color-cta-light: var(--adn-color-yellow-light);
    --adn-color-cta-light-2: var(--adn-color-yellow-light-2);

    // GREEN
    --adn-color-green: #8cd158;
    --adn-color-green-light: #bae49b;
    --adn-color-green-light-2: #ddf1cd;

    // GREY
    --adn-color-grey: #d9d9d9;
    --adn-color-grey-light: #c3c3c3;
    --adn-color-grey-light-2: #ede9e9;

    // COMMON
    --adn-color-border: rgba(172, 172, 172, 0.3);

    --nutrition: var(--adn-color-yellow);

    // borders
    --border-color: #d2d7d9;
    --border-radius-card: 16px;
    --border-chip: 25px;
    --border-line-color: rgba(0, 0, 0, 0.15);

    //box-shadows
    --box-shadow-light: 0px 1px 4px rgba(0, 0, 0, 0.15);
    --box-shadow-primary: 0px 0px 0 2px var(--adn-color-primary);
    --box-shadow-secondary: 0px 0px 0 2px var(--adn-color-genetic-dark);

    /////////////// LEGACY
    --white: var(--adn-color-white);
    --black: var(--adn-color-black);

    --grey: #f1ecec;
    --grey-light: #f6f6f6;
    --grey-dark: #e3e3e3;

    --primary-dark: #53b5aa; // var(--primary-dark);
    --primary-light: #b6e7e3; // var(--primary-light);
    --primary-lighter: #d5f1ef; // var(--primary-lighter);
    --primary-lightest: #edf9f8; // var(--primary-lightest);
    --primary: var(--adn-color-primary);
    --secondary: var(--adn-color-genetic-dark); // var(--secondary);;
    --light-secondary: #544c4c; // var(--secondary);;
    --lighter-secondary: #544c4c99; // var(--secondary);;
    --lightest-secondary: #544c4c66;

    --warning: #fe9d0e;
    --success: #9bc804;
    --protected: var(--success);
    --danger: var(--africa);
    --danger-light: #ff790c20;
    --danger: var(--africa);
    --warning: var(--nutrition);

    --africa: #f3796d;
    --america: #b6e44d;
    --europe: #8cccff;
    --east-asia: #eff038;
    --south-asia: var(--adn-color-genetic-dark);

    --fitness: var(--adn-color-green);
    --longevity: var(--adn-color-purple);
    --personality: var(--adn-color-blue);

    --tipsa: #fd4e00;
    --eurofins: green;
    --cryolabs: #2e85c5;
    --dnaverse: #000;
    --miadnchile: #40a9ff;
    --youtube: #fd0c01;
    --adntro: var(--adn-color-primary);
    --ancestry: var(--adn-color-primary-light);

    --covid: var(--adn-color-purple);

    --labs: var(--adn-color-orange-2);
    --curious: var(--labs);

    --health: var(--adn-pink);
    --pink: var(--adn-pink);
    --protected: var(--success);

    //PENDING

    --border-color: #d2d7d9;
    --danger-light: #ff790c20;
    --grey: #f1ecec;
    --grey-slim-chart: #f1f2f2;
    --grey-light: #f6f6f6;
    --grey-dark: #e3e3e3;
    --danger: var(--africa);
    --warning: var(--nutrition);
    --border-radius-card: 16px;
    --learning: #0086da;
    --market: var(--adn-color-primary-light);
    --mosquito: #626892;
    --nutrition-light: #ffcf3d70;
    // --nutrition: #f2c335;
    --orange-accent: var(--nutrition);
    --orange-icon: var(--africa);
    --orange-dark: #ffb12c;
    --pain: #fac75f;
    --education: var(--secondary);
    --entertainment: #f29f05;
    --review: var(--pink);
    --selfknowledge: var(--personality);
}
