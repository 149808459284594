/**
 * TYPOGRAPHY
 */

:root {
  --adn-font-body: "Nunito Sans", sans-serif;
  --adn-text-color-title: var(--secondary);
  --adn-text-color-body: #544c4c;
  --adn-text-color-light: rgba(84, 76, 76, 0.6);
  --adn-text-font-size-hero: 40px;
  --adn-text-font-size-h1: 24px;
  --adn-text-font-size-h2: 22px;
  --adn-text-font-size-h3: 18px;
  --adn-text-font-size-xxs: 10px;
  --adn-text-font-size-xs: 12px;
  --adn-text-font-size-sm: 13px;
  --adn-text-font-size-body: 14px;
  --adn-text-font-size-body-2: 16px;
  --adn-text-font-size-hero-desktop: 48px;
  --adn-text-font-size-h1-desktop: 30px;
  --adn-text-font-size-h2-desktop: 28px;
  --adn-text-font-size-h3-desktop: 20px;
  --adn-text-font-size-xxs-desktop: 11px;
  --adn-text-font-size-xs-desktop: 13px;
  --adn-text-font-size-sm-desktop: 14px;
  --adn-text-font-size-body-desktop: 15px;
  --adn-text-font-size-body-2-desktop: 17px;
}

.adn-hero {
  font-size: var(--adn-text-font-size-hero);
  line-height: 1;
  font-weight: 800;
  color: var(--adn-text-color-title);
  @media (min-width: 767px) {
    font-size: var(--adn-text-font-size-hero-desktop);
  }
}

.adn-h1 {
  font-family: var(--adn-font-body);
  font-size: var(--adn-text-font-size-h1);
  line-height: 1;
  font-weight: 600;
  color: var(--adn-text-color-title);
  @media (min-width: 767px) {
    font-size: var(--adn-text-font-size-h1-desktop);
  }
}
.adn-h2 {
  font-family: var(--adn-font-body);
  font-size: var(--adn-text-font-size-h2);
  line-height: 1;
  font-weight: 600;
  color: var(--adn-text-color-title);
  @media (min-width: 767px) {
    font-size: var(--adn-text-font-size-h2-desktop);
  }
}
.adn-h3 {
  font-family: var(--adn-font-body);
  font-size: var(--adn-text-font-size-h3);
  line-height: 1;
  font-weight: 600;
  color: var(--adn-text-color-title);
  @media (min-width: 767px) {
    font-size: var(--adn-text-font-size-h3-desktop);
  }
}
.adn-text-body {
  font-family: var(--adn-font-body);
  font-size: var(--adn-text-font-size-body);
  line-height: 1.4em;
  color: var(--adn-text-color-body);
  @media (min-width: 767px) {
    font-size: var(--adn-text-font-size-body-desktop);
  }
}
.adn-text-body-2 {
  font-family: var(--adn-font-body);
  font-size: var(--adn-text-font-size-body-2);
  line-height: 1.3em;
  color: var(--adn-text-color-body);
  @media (min-width: 767px) {
    font-size: var(--adn-text-font-size-body-2-desktop);
  }
}
.adn-text-sm {
  font-family: var(--adn-font-body);
  font-size: var(--adn-text-font-size-sm);
  line-height: 1.3em;
  color: var(--adn-text-color-body);
  @media (min-width: 767px) {
    font-size: var(--adn-text-font-size-sm-desktop);
  }
}
.adn-text-xs {
  font-family: var(--adn-font-body);
  font-size: var(--adn-text-font-size-xs);
  line-height: 1.3em;
  color: var(--adn-text-color-body);
  @media (min-width: 767px) {
    font-size: var(--adn-text-font-size-xs-desktop);
  }
}
.adn-text-xxs {
  font-family: var(--adn-font-body);
  font-size: var(--adn-text-font-size-xxs);
  line-height: 1.3em;
  color: var(--adn-text-color-body);
  @media (min-width: 767px) {
    font-size: var(--adn-text-font-size-xxs-desktop);
  }
}

.adn-text-badge {
  font-family: var(--adn-font-body);
  font-size: var(--adn-text-font-size-xxs);
  line-height: 1;
  text-transform: uppercase;
  @media (min-width: 767px) {
    font-size: var(--adn-text-font-size-xxs-desktop);
  }
}
.adn-text-link {
  font-family: var(--adn-font-body);
  color: var(--adn-text-color-title);
  text-decoration: underline !important;
  cursor: pointer;
  &:hover {
    color: var(--primary) !important;
  }
}
/*
.adn-text-button-sm {
  font-size: 9px;
  line-height: 1;
  text-transform: uppercase;
  @media (min-width: 767px) {
    font-size: 10px;
  }
}
.adn-text-button {
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  @media (min-width: 767px) {
    font-size: 14px;
  }
}
*/
.adn-font-light {
  font-weight: 300;
}
.adn-font-regular {
  font-weight: 400;
}
.adn-font-semibold {
  font-weight: 600;
}
.adn-font-bold {
  font-weight: 800;
}
.adn-font-italic{
  font-style: italic;
}

b,
strong {
  font-weight: 800;
}

.adn-text-dark {
  color: var(--adn-text-color-title);
}
.adn-text-grey {
  color: var(--adn-text-color-body);
}
.adn-text-danger {
  color: var(--adn-color-orange);
}
.adn-text-success {
  color: var( --success);
}
.adn-text-white {
  color: var(--white);
}
.adn-text-primary {
  color: var(--adn-color-primary);
}

// LEGACY
span.strong {
  font-weight: 400;
}

.line-height-small {
  line-height: 1.1em;
}

.adntro-h1 {
  font-size: 2.2rem;
  font-weight: bold;
  color: var(--secondary);
  @media (min-width: 767px) {
    font-size: 3rem;
  }
}
.adntro-h2 {
  font-size: 1.7rem;
  font-weight: bold;
  color: var(--secondary);
  @media (min-width: 767px) {
    font-size: 2.2rem;
  }
}
.adntro-h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--secondary);
  @media (min-width: 767px) {
    font-size: 2rem;
  }
}
.adntro-h4 {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--secondary);
}
.adntro-h5 {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--secondary);
}
.adntro-h6 {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--secondary);
}
.adntro-small-note {
  font-size: 12px !important;
  color: var(--secondary) !important;
}

.adntro-common-text {
  color: var(--secondary);
}

// TODO revisar con Jorge estandarización de textos
p {
  line-height: 1.4em;
  color: var(--secondary);
  &.common {
    font-size: 16px;
    color: var(--secondary);
  }
}

/// old de aquí para arriba
