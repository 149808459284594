.bg-adntro-color-yellow-light {
  background-color: var(--adn-color-yellow-light-2);
}

//LEGACY COLORS
.bg-gradient-primary {
  background-image: linear-gradient(208deg, #58b8ad, #243c47);
}
.bg-gradient-secondary {
  background-image: linear-gradient(240deg, #b2576e 0%, #15323f 100%);
}
.bg-gradient-alt {
  background-image: linear-gradient(240deg, #614757 0%, #15323f 100%);
}

.bg-adntro-white {
  background-color: var(--white);
}
.bg-adntro-genetic-dark {
  background-color: var(--adn-color-genetic-dark);
}

.bg-adntro-light {
  background-color: var(--primary-lighter);
}
.bg-adntro-green {
  background-color: var(--adn-color-green-light);
}
.bg-adntro-lightest {
  background-color: var(--primary-lightest);
}
.bg-adntro-pink {
  background-color: #f8bdc9;
}
.bg-adntro-red {
  background-color: #f8afa6;
}
.bg-adntro-danger {
  background-color: var(--danger);
}

.bg-adntro-white {
  background-color: var(--white);
}

.color-primary {
  color: var(--primary);
}
.color-pink {
  color: var(--pink);
}
.color-black {
  color: var(--secondary);
}

.color-white {
  color: var(--white);
}
.color-success {
  color: var(--success);
}
.color-danger {
  color: var(--danger);
}

.adn-bg-blue {
  background-color: var(--secondary);
}
