// Esto es por los pills de dietas
.pill-text {
  border-radius: 17px;
  height: 35px;
  line-height: 9px;
  margin: 5px 5px;
  cursor: auto;
}


span.description > p {
  color: white;
}

//Override del componente barcode-scanner-livestream
.scanner {
  text-align: center;
}
.paypal-button-container > div {
  z-index: 1;
}
zxing-scanner {
  video {
    border-radius: 15px;
    width: 100% !important;
    display: none;
  }

  &.loaded {
    video {
      display: inline-block;
      border: 4px solid var(--primary);
    }
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  zxing-scanner video {
    width: 80% !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  zxing-scanner video {
    width: 70% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  zxing-scanner video {
    width: 50% !important;
  }
}
.grecaptcha-badge{
  bottom: 85px!important;
}